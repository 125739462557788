import React, { Component } from "react";
import { FormGroup, Form, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewTrade.css";
import  Amplify, { API } from "aws-amplify";

export default class NewTrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      exchangeItem: "",
      desiredItem: "",
      exchangeQty: 1,
      desiredQty: 1
    };
    Amplify.Logger.LOG_LEVEL = 'DEBUG';

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
         userPoolId: config.cognito.USER_POOL_ID,
         identityPoolId: config.cognito.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID
         },
       API: {
        endpoints: [
        {
        name: "skytrade",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
        },
        ]
       }
     });
    }

  validateForm() {
   // return this.state.content.length > 0;
   return this.state.exchangeItem.length > 0 && this.state.desiredItem.length > 0;
   //return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.createTrade();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }
  createTrade() {
    API.post("skytrade", "/skytrade", {
      body: {
        "itemName": this.state.desiredItem,
        "quantity": this.state.desiredQty,
        "requestType": "buy",
      }
    })
    return API.post("skytrade", "/skytrade", {
      body: {
        "itemName": this.state.exchangeItem,
        "quantity": this.state.exchangeQty,
        "requestType": "sell",
      }
    })
  }
  render() {
    return (
      <div className="NewTrade">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="exchangeItem">
            <ControlLabel>Exchange Item</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.exchangeItem}
            />
          </FormGroup>
          <FormGroup controlId="exchangeQty">
            <ControlLabel>Exchange Quantity</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.exchangeQty}
            />
          </FormGroup>
          <FormGroup controlId="desiredItem">
            <ControlLabel>Desired Item</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.desiredItem}
            />
          </FormGroup>
          <FormGroup controlId="desiredQty">
            <ControlLabel>Desired Quantity</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.desiredQty}
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
