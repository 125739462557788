export default {
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://3qem53s1e8.execute-api.us-east-2.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_Hwyk7izMf",
      APP_CLIENT_ID: "6o9m6p2mndkuhfg5i1jsmj8a7u",
      IDENTITY_POOL_ID: "us-east-2:0eb84a06-c0d3-4d77-829a-77947f83936b"
    }
  };