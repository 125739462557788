import React, { Component } from "react";
import { API, Storage } from "aws-amplify";

export default class Requests extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      request: null,
      content: "",
    };
  }

  async componentDidMount() {
    try {
      const request = await this.getRequest();
      const { content } = request;

      this.setState({
        request,
        content,
      });
    } catch (e) {
      alert(e);
    }
  }

  getRequest() {
    return API.get("skytrade", `/requests/${this.props.match.params.id}`);
  }

  render() {
    return (
      <div className="Requests">
      </div>
    );
  }
}
